import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Header from './Header'
function Navbar() {
  return (
    <div className="App">
      <Header />
    </div>
  );

}

export default Navbar