import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import bropic from '../assets/bro pic.jpg';
import mypic from '../assets/my profile.jpg';
import healthpicone from '../assets/Health pic.jpeg';
import healthpictwo from '../assets/healthpic2.jpeg';
import healthpicthree from '../assets/Health pic3.jpeg';
export default {
  email,
  mobile,
  profile,
  circle,
  bropic,
  mypic,
  healthpicone,
  healthpictwo,
  healthpicthree
};